document.querySelectorAll('.ga4-tracking').forEach(function(menu_item){
    menu_item.addEventListener('click', function () {
        let dataEvent = this.getAttribute("data-event");
        let dataNavigation = this.getAttribute("data-navigation");
        let additionalData;

        if (typeof dataLayer !== "undefined") {
            if(dataNavigation && dataNavigation.trim() != ""){
                additionalData = {
                    "navigationTab" : dataNavigation
                }
            }
            
            dataLayer.push({
                'event': dataEvent,
                ...additionalData,
                'pageUrl': window.location.href,
            });
        }
        return false;
    }, false);
});